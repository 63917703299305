.layout {
    height: 100vh;
    background: url("https://pcmanager.microsoft.com/assets/digitalLivingMain.svg");
    background-size: cover;
    padding: 0;
    overflow-y: scroll;
    scroll-behavior: smooth;
}

@keyframes colorChange {
    0% {
        background-position: 0% 0%;
    }

    50% {
        background-position: 100% 100%;
    }

    100% {
        background-position: 0% 0%;
    }
}

.index {
    width: auto;
    height: 100%;
    margin: 0 auto;
}

.outsideTitle {
    width: max-content;
    height: max-content;
    background-color: #353c42;
    padding: 0.5rem 1rem;
    border-radius: 1rem;
    margin: 1rem;
    font-size: 1rem;
    font-weight: bold;
    border-bottom: 2px double #f2f2ff;
    color: #f2f2ff;
}

.header {
    position: fixed;
    top: 20px;
    border-radius: 19px;
    background-color: rgba(250, 248, 248, 0.5);
    opacity: 1;
    transition: width 1s ease-out;
    z-index: 99;
}

.logo {
    width: 10%;
    color: #fafafa;
    font-size: 2rem;
    text-align: center;
    font-weight: 500;
}

.menuItem {
    font-size: 1.1rem;
    border-radius: 19px;
    position: sticky;
    top: 20px;
    background-color: rgba(250, 248, 248, 0.5);
    line-height: 64px;
}

::global(.ant-btn-primary) {
    background: #353c42 !important;
}

.main {
    min-height: calc(100vh - 3rem);
    /*padding: 2rem 0;*/
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footer {
    width: 100%;
    height: 0.5rem;
    /* position: absolute; */
    /* bottom: 0; */
    display: flex;
    justify-content: right;
    background-color: #2d2d2d;
    color: #fafafa;
    /* margin-top: 20px; */
    border-radius: 6px 6px 0px 0px;
}

.footer a {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-grow: 1; */
}

.title a {
    color: #0070f3;
    text-decoration: none;
}

.title a:hover,
.title a:focus,
.title a:active {
    text-decoration: underline;
}

.title {
    margin: 0;
    line-height: 1.15;
    font-size: 4rem;
}

.title,
.description {
    text-align: center;
}

.description {
    width: 68%;
    line-height: 1.5;
    font-size: 1.5rem;
    margin-bottom: 2.4rem;
}

.search {
    border-radius: 19px !important;
    background: #353c42;
}

.code {
    background: #fafafa;
    border-radius: 5px;
    padding: 0.75rem;
    font-size: 1.1rem;
    font-family: Menlo, Monaco, Lucida Console, Liberation Mono,
        DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace;
}

.grid {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: 100%;
}

.card {
    margin: 1rem 1rem;
    text-align: left;
    color: inherit;
    text-decoration: none;
    border-radius: 10px;
    transition: transform 0.5s ease;
    width: max-content;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1),
        0 0px 5px rgba(204, 204, 204, 0.1) inset,
        0 0px 4px rgba(204, 204, 204, 0.1), 0 0px 6px rgba(204, 204, 204, 0.1),
        0 16px 16px rgba(0, 0, 0, 0.1);
    background: linear-gradient(360deg,
            rgba(250, 248, 248, 0.5),
            rgba(0, 0, 200, 0));
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}

.card:hover,
.card:focus,
.card:active {
    transform: translateY(-20px);
}

.card h2 {
    margin: 0 0 1rem 0;
    font-size: 1.5rem;
}

.card p {
    margin: 0;
    line-height: 1.5;
}

.toggleMode {
    position: absolute;
    top: 5rem;
    right: 15px;
    border-radius: 5px;
    z-index: 99;
}

.modeButton {
    padding: 0;
    border: none;
    z-index: 99;
}

.modeButton:hover {
    color: #000 !important;
    /* font-weight: bold; */
    border: none;
    scale: 1.1;
}

@media (min-width: 1423px) and (max-width: 2560px) {
    .grid {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        align-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
    }
}

/* 平板电脑 */
@media (min-width: 768px) and (max-width: 1023px) {
    .div {
        width: 100%;
        height: 100%;
        margin: 0 auto;
    }

    .card {
        width: 350px;
    }

    .grid {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        flex-direction: row;
        width: 100%;
    }

    .ant-layout-header {
        padding: 0;
    }

    .header {
        /* padding: 0;
        display: flex;
        justify-content: flex-start;
        align-items: center; */
        position: absolute;
        top: 20px;
    }

    .footerTime {
        display: none !important;
    }
}

/* 小型设备 */
@media (max-width: 767px) {
    .header {
        position: sticky;
        top: 0.5rem;
        right: -1px;
        margin: 0 auto;
        transition: width 1s ease-out;
        z-index: 99;
    }

    .logo {
        display: none;
    }

    .card {
        margin: 1rem auto;
    }

    .grid {
        width: 100%;
        flex-direction: row;
    }

    .footerAuthor {
        margin: 0 auto !important;
    }

    .footerPower {
        display: none !important;
    }
}

.loader {
    width: 120px;
    height: 50px;
    padding-top: 100px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -350%);
    display: flex;
    justify-content: space-between;
}

.tone {
    width: 12px;
    height: 50px;
    border-radius: 4px;
    /*background-color: #0077CC;*/
    background-color: #333;
    animation: toneUp 1s cubic-bezier(0.66, -0.28, 0.22, 1.28) infinite;
}

.tone:nth-child(1) {
    animation-delay: -0.4s;
}

.tone:nth-child(2) {
    animation-delay: -0.3s;
}

.tone:nth-child(3) {
    animation-delay: -0.2s;
}

.tone:nth-child(4) {
    animation-delay: -0.1s;
}

.tone:nth-child(5) {
    animation-delay: 0s;
}

@keyframes toneUp {
    0% {
        transform: translateY(0);
    }

    20% {
        transform: translateY(-20px);
    }

    40% {
        transform: translateY(0);
    }

    100% {
        transform: translateY(0);
    }
}

.dark {
    background-color: black;
    color: #fff;
    background-image: none;
}