@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--dark-mode-color: #fff;
	--dark-mode-background: #000;
}

@font-face {
	font-family: "JetBrains Sans";
	src: url(https://resources.jetbrains.com/storage/jetbrains-sans/JetBrainsSans.woff2);
}

html,
body {
	font-size: 16px;
	font-family: JetBrains Sans;
	width: 100%;
	height: 100vh;
	font-weight: 400;
	padding: 0;
	margin: 0;
	/* background-color: #f2f3f5; */
	/* color: var(--dark-mode-color); */
	/* background-color: var(--dark-mode-background); */

	/*background: url("https://browser6.qhimg.com/t015beebca05de6ff6b.jpg");*/
	/* image-rendering: crisp-edges; */
	/*animation: fadeInAnimation cubic-bezier(.88,.16,1,.64) 2s;*/
	/*animation-iteration-count: 1;*/
	/*animation-fill-mode: forwards;*/
	overflow-y: hidden;
	/* will-change: transform;
	scroll-behavior: smooth;
	transform: translateZ(0); */
}

/* 滚动条整体样式 */
::-webkit-scrollbar {
	width: 2px;
	height: 1px;
	/* 滚动条高度 */
	/* position: fixed; */
	/* left: 0; */
}

/* 滚动槽 */
::-webkit-scrollbar-track {
	background-color: #fff;
	/* 滚动槽背景色 */
}

/* 滚动块 */
::-webkit-scrollbar-thumb {
	background-color: #29c6cf;
	/* 滚动块背景色 */
	border-radius: 5px;
	/* 滚动块圆角 */
	position: fixed;
	left: 0;
}

/* 鼠标悬停在滚动条上的样式 */
::-webkit-scrollbar-thumb:hover {
	background-color: #29c6cf;
	/* 滚动块背景色 */
}

@keyframes fadeInAnimation {
	0% {
		opacity: 0;
	}

	50% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}

a {
	color: inherit;
	text-decoration: none;
}

* {
	box-sizing: border-box;
}

.ant-layout-header {
	padding: 0 !important;
}

/* .ant-page-header-back-button{
  color: #fff !important;
}

.ant-page-header-heading-title {
  color: #fff !important;
} */

#__next {
	height: 100%;
}

:where(.css-dev-only-do-not-override-18iikkb).ant-menu-light.ant-menu-horizontal>.ant-menu-item-selected,
:where(.css-dev-only-do-not-override-18iikkb).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-item-selected,
:where(.css-dev-only-do-not-override-18iikkb).ant-menu-light.ant-menu-horizontal>.ant-menu-submenu-selected,
:where(.css-dev-only-do-not-override-18iikkb).ant-menu-light>.ant-menu.ant-menu-horizontal>.ant-menu-submenu-selected {
	color: #29c6cf;
}

.ant-menu-horizontal>.ant-menu-item a:hover {
	color: #29c6cf;
}

.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark)>.ant-menu-submenu-selected::after {
	/*width: 15px;*/
	border-bottom: 2px solid #29c6cf;
}

.ant-spin {
	color: #29c6cf;
}

.ant-spin .ant-spin-dot-item {
	background-color: #29c6cf;
}

a:hover {
	color: #29c6cf;
}

.ant-menu-item-selected .ant-menu-title-content {
	color: #29c6cf;
}

li {
	list-style: none;
}